// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import $ from 'jquery';
import 'bootstrap';
import '../stylesheets/website.scss';
import 'client_global';
import 'course_registration_reminder';
import 'course_signup_modals';
import 'course_search';
import 'sticky_button';
import 'main_menu';
import 'markdown_editor';
import AOS from 'aos';

require('@rails/ujs').start();
require('turbolinks').start();

window.$ = window.jquery = $;
$(document).on('turbolinks:load', function () {
  // Initialize Animate On Scroll (AOS) library
  AOS.init({
    duration: 300,
    delay: 0,
    easing: 'ease-out-quart',
    once: true
  });

  // enable popper and tooltips
  $('[data-behavior="tooltip"]').tooltip();
  $('[data-behavior="popover"]').popover();
});

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Hide the header on scroll down, show on scroll up
// Adapted from:
// https://medium.com/@mariusc23/hide-header-on-scroll-down-show-on-scroll-up-67bbaae9a78c
const stickyHeader = (function () {
  const minScrollDistance = 5;
  let $header;
  let navbarHeight;

  let didScroll = false;
  let lastScrollTop = 0;

  const handleHeader = function () {
    const st = $(this).scrollTop();

    // Make sure they scroll more than delta
    if (Math.abs(lastScrollTop - st) <= minScrollDistance) {
      return;
    }

    // If they scrolled down and are past the navbar, add class .nav-up.
    // This is necessary so you never see what is "behind" the navbar.
    if (st > lastScrollTop && st > navbarHeight) {
      // Scroll Down
      $header.addClass('nav-up');
    } else {
      // Scroll Up
      if (st + $(window).height() < $(document).height()) {
        $header.removeClass('nav-up');
      }
    }

    lastScrollTop = st;
  };

  const registerScroll = function (event) {
    didScroll = true;
  };

  const checkInterval = function () {
    if (didScroll) {
      handleHeader();
      didScroll = false;
    }
  };

  const handleFirstTab = function (e) {
    if (e.key === 'Tab') {
      $('body').addClass('user-is-tabbing');
      $(window).off('keydown', handleFirstTab);
    }
  };

  const init = function () {
    // Set vars
    $header = $('header');
    navbarHeight = $header.outerHeight();

    // Add events
    $(window).on('keydown', handleFirstTab);
    $(window).scroll(registerScroll);

    // Set interval
    setInterval(checkInterval, 250);
  };

  return {
    init: init
  };
})();

$(document).on('turbolinks:load', stickyHeader.init);

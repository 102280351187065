require('jquery_masked_input.min.js');

const signupModalHandler = (function () {
  const init = function () {
    $('body').on('focus blur', '.animate-label', window.animateLabel);
    $('.animate-label').each(window.animateLabel);
    $('body #modal-window').on('click', '.attendee-select button', updateAttendeeCount);
    $('body #modal-window').on('click', '#reg-copy-link-button', copyLinkText);
    $('body #modal-window').on('focus', '#registrant_phone', applyTelephoneMask);
    $('body #modal-window').on('change', '.modal-body.part-1 #course_date', updateRegistrationRoute);
    $('body #modal-window').on('click', '.btn.course-register', populateTimeZoneInput);
  };

  // This is being added to the window in order to deal with
  // scoping issues present when rendering the modal component
  // from the registrant controller
  window.animateLabel = function () {
    let $label;
    const $input = $(this);
    const inputID = $input.attr('id');

    // Select the label based on the input's ID
    if (inputID) {
      $label = $(`label[for="${inputID}"]`);
    }

    // If we still do not have a valid label then bail out
    if (!$label || !$label[0]) {
      return;
    }

    // If the input has focus or value make sure the label
    // has the 'active' class. Otherwise, remove the active
    // class, if it exists.
    if ($input.is(':focus') || $input.val()) {
      $label.addClass('active');
    } else {
      $label.removeClass('active');
    }
  };

  // When the select element on a location based course
  // signup form has a change this method updates the form's
  // action attribute to be the value of the chosen option
  // element (a path string).
  const updateRegistrationRoute = function () {
    const $form = $('.modal-body.part-1 form');
    const registrationRoute = $(this).find('option:selected').attr('value');
    $form.attr('action', registrationRoute);
  };

  const applyTelephoneMask = function () {
    $('#registrant_phone').mask('1 (999) 999-9999', { autoclear: false });
  };

  const updateAttendeeCount = function () {
    const $attendeeInput = $('#seats_reserved');
    let attendeeCount = parseInt($attendeeInput.val());

    if (!attendeeCount) {
      $attendeeInput.val('1');
      return;
    }

    // Ensure attendeeCount is 1 or 2
    if ($(this).is('#attendee-minus') && attendeeCount > 1) {
      $attendeeInput.val(--attendeeCount);
    } else if ($(this).is('#attendee-plus') && attendeeCount < 2) {
      $attendeeInput.val(++attendeeCount);
    }
  };

  const copyLinkText = function () {
    $('#reg-copy-link-field').select();

    // execCommand('copy') is deprecated, but no other alternative
    // has surfaced. We should expect to need to update this in the future
    // along with the WP version (as long as it is still active) once
    // another options becomes available or once the feature is fully
    // removed.
    document.execCommand('copy');

    $('#reg-copy-message').addClass('active');
    setTimeout(function () {
      $('#reg-copy-message').removeClass('active');
    }, 2000);
  };

  const populateTimeZoneInput = function () {
    $('#registrant_time_zone').val(moment.tz.guess());
  }

  return {
    init: init
  };
})();

$(document).on('turbolinks:load', signupModalHandler.init);

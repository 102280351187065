const stickyButton = (function () {
  const init = function () {
    const sticky = $('.sticky');

    if (sticky.length === 0) {
      return;
    }

    let stickyClone;
    let stickyTop = sticky.offset().top - 8;
    let scrollTop;
    const $window = $(window);

    // Don't make the button sticky if it is "disabled"
    if (sticky.hasClass('disabled')) {
      return false;
    }

    // Ensure button stays at proper offset even if window is resized
    $window.on('resize', function () {
      stickyTop = sticky.offset().top - 8;
    });

    $window.on('scroll', function (e) {
      scrollTop = $window.scrollTop();

      if (scrollTop >= stickyTop && !stickyClone) {
        // Attach a clone to replace the "missing" body height
        stickyClone = sticky.clone().prop('class', sticky.prop('class') + '-clone');
        stickyClone = stickyClone.insertBefore(sticky);
        sticky.addClass('fixed');
      } else if (scrollTop < stickyTop && stickyClone) {
        // Since sticky is in the viewport again, we can remove the clone and the class
        stickyClone.remove();
        stickyClone = null;
        sticky.removeAttr('style');
        sticky.removeClass('fixed');
      }
    });
  };

  return {
    init: init
  };
})();

$(document).on('turbolinks:load', function () {
  stickyButton.init();
});

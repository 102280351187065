// Handles main menu functionality (including mobile)
const mainMenu = (function () {
  let $html;
  let $parentMenuItems;
  let $mobileMenuToggle;

  const initialize = function () {
    $html = $('html');
    $parentMenuItems = $('.main-menu-link-disabled');
    $mobileMenuToggle = $('#menu-toggle');

    $parentMenuItems.on('click', disableLink);
    $mobileMenuToggle.on('click', toggleMenu);
  };

  const disableLink = function (e) {
    e.preventDefault();
  };

  const toggleMenu = function () {
    $html.toggleClass('menu-is-active');
  };

  return {
    init: initialize
  };
})();

$(document).on('turbolinks:load', function () {
  mainMenu.init();
});

/* global google */
/* eslint no-undef: "error" */

import themes from 'google_map_themes';
import markerIconSVGs from 'google_map_marker_icons';

const courseSearch = (function () {
  // https://en.wikipedia.org/wiki/Geographic_center_of_the_contiguous_United_States
  const US_CENTER = Object.freeze({ lat: 39.8283, lng: -98.5795 });
  const NATION_ZOOM_LEVEL = 4; // Why 4? At this view level (US Map view), this one looks good.
  const ADDR_ZOOM_LEVEL = 14; // Why 14? Tried different levels ... this one looks good for addresses.
  const SEARCH_RADIUS = 50; // 50 miles; the same as RWA V1 course search.
  // Google categorizes US states as "administrative_area_level_1".
  // https://developers.google.com/maps/documentation/geocoding/overview#Types
  const STATE_LEVEL_SEARCH = 'administrative_area_level_1';
  let $courseListing;
  let geocoder;
  let markerIcons;
  let map;
  let markers = [];
  let $selectedCourseWrapper;
  let $searchInput;
  let $searchButton;
  let $resultsRow;
  let $noResultsOverlay;
  let $noResultsQuery;
  let searchEndpoint;
  let searchStateEndpoint;
  let searchCoordinatesEndpoint;

  const init = function () {
    const searchParams = new URLSearchParams(window.location.search);
    const mapElem = document.getElementById('google-map-course-search');
    if (mapElem) {
      markerIcons = initMarkerIcons();
      $selectedCourseWrapper = $('.selected-course-wrapper');
      $searchInput = $('.course-search-bar input');
      $searchButton = $('.course-search-bar button');
      $resultsRow = $('.course-results');
      $noResultsOverlay = $('.no-course-results');
      $noResultsQuery = $('#no-results-query');
      $courseListing = $('#course-listing');
      searchEndpoint = $('#google-map-course-search').attr('data-search-endpoint') + '.json';
      searchStateEndpoint = $('#google-map-course-search').attr('data-search-state-endpoint');
      searchCoordinatesEndpoint = $('#google-map-course-search').attr('data-search-coordinates-endpoint');
      geocoder = new google.maps.Geocoder();

      addEventHandlers();
      initMap(mapElem);
      if (searchParams.has('q') && searchParams.get('q')) {
        $searchInput.val(searchParams.get('q'));
        $searchButton.trigger('click');
      } else {
        getUpcomingCourses();
      }
    }
  };

  const addEventHandlers = function () {
    $courseListing.on('click', '.course-container', activateListingOnMap);
    $courseListing.on('scroll', updateScrollIndicator);

    $searchButton.click(function () {
      const query = $searchInput.val();
      getCoursesInArea(query);
    });

    $(document).on('keypress', function (e) {
      if (e.key === 'Enter' && $searchInput.is(':focus')) {
        e.preventDefault();
        const query = $searchInput.val();
        getCoursesInArea(query);
      }
    });
  };

  const initMap = function (mapElem) {
    map = new google.maps.Map(mapElem, {
      center: US_CENTER,
      styles: themes.silver,
      zoom: NATION_ZOOM_LEVEL,
      disableDefaultUI: true,
      zoomControl: true,
      scaleControl: true
    });

    map.addListener('click', function () {
      hideSelectedCourse();
    });
  };

  const initMarkerIcons = function () {
    return {
      blue: {
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(12, 36),
        scaledSize: new google.maps.Size(24, 36),
        url: markerIconSVGs.blue
      },
      red: {
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(18, 54),
        scaledSize: new google.maps.Size(36, 54),
        url: markerIconSVGs.red
      }
    };
  };

  const clearMarkers = function () {
    markers.forEach(function (marker) {
      marker.setMap(null);
    });
    markers = [];
  };

  const getUpcomingCourses = function () {
    $.ajax({
      type: 'GET',
      url: searchEndpoint,
      dataType: 'json',
      success: function (courses) {
        if (courses.length === 0) {
          return;
        }

        $courseListing.empty();
        hideSelectedCourse();
        clearMarkers();
        courses.forEach(function (course) {
          markCourseOnMap(course);
          $courseListing.append(course.template);

          // Set the directions URL for mobile
          $courseListing.last().find('.get-directions').attr('href', directionsUrl(course));
        });
        $courseListing.append("<div class='end-of-results'>End of results</div>");
        updateScrollIndicator();
      }
    });
  };

  const getCoursesInArea = function (query) {
    // First get the coordinates of the location the user searched for.
    geocoder.geocode({
      address: query,
      componentRestrictions: { country: 'US' }
    }, function (results) {
      // Handle cases where the geocoder isn't able to identify a real location from the inputs.
      if (results.length === 0) {
        showNoResultsForm();
        return;
      }

      let searchData;
      let endpoint;
      if (results[0].types[0] === STATE_LEVEL_SEARCH) {
        searchData = {
          search_state_abbr: results[0].address_components[0].short_name // I.e. "CA"
        };
        endpoint = searchStateEndpoint;
      } else {
        searchData = {
          search_lat: results[0].geometry.location.lat(),
          search_lng: results[0].geometry.location.lng(),
          search_radius: SEARCH_RADIUS
        };
        endpoint = searchCoordinatesEndpoint;
      }

      $.ajax({
        type: 'GET',
        url: endpoint,
        dataType: 'json',
        data: searchData,
        success: updateDisplay
      });
    });
  };

  const updateDisplay = function (courses) {
    if (courses.length > 0) {
      refreshMap(courses);
    } else {
      showNoResultsForm();
    }
  };

  const refreshMap = function (courses) {
    const bounds = new google.maps.LatLngBounds();

    $noResultsOverlay.hide();
    $resultsRow.show();

    $courseListing.empty();
    updateSearchPlaceholder(courses);
    hideSelectedCourse();
    clearMarkers();

    courses.forEach(function (course) {
      markCourseOnMap(course);
      $courseListing.append(course.template);
      bounds.extend(new google.maps.LatLng(
        parseFloat(course.location.latitude),
        parseFloat(course.location.longitude)
      ));
    });

    // Zoom the map to fit the markers (temporarily setting a max zoom level).
    map.setOptions({ maxZoom: ADDR_ZOOM_LEVEL });
    map.fitBounds(bounds, 50); // 50 extra pixels of padding
    map.setOptions({ maxZoom: undefined });
    $courseListing.append("<div class='end-of-results'>End of results</div>");
  };

  const updateSearchPlaceholder = function (courses) {
    const count = courses.length;
    $searchInput.attr('placeholder', `${count} results found for "${$searchInput.val()}"`);
    $searchInput.val('');
  };

  const markCourseOnMap = function (course) {
    const locationCoords = {
      lat: parseFloat(course.location.latitude),
      lng: parseFloat(course.location.longitude)
    };

    const marker = new google.maps.Marker({
      map: map,
      title: course.title,
      icon: markerIcons.blue,
      position: locationCoords
    });

    const len = markers.length;

    marker.addListener('click', function () {
      hideSelectedCourse();
      showSelectedCourse(course);
      marker.setIcon(markerIcons.red);
      $('.course-container').removeClass('selected');
      $('#course-container-' + len).addClass('selected');
      setTimeout(scrollToSelectedCourse, 200);
    });

    markers.push(marker);
  };

  const showSelectedCourse = function (course) {
    const csz = `${course.location.city} ${course.location.state} ${course.location.zip}`;
    $selectedCourseWrapper.find('h2').text(displayTitle(course.course_type.title));
    $selectedCourseWrapper.find('.course-address-title').text(course.location.title);
    $selectedCourseWrapper.find('.course-address-street').text(course.location.street_address);
    $selectedCourseWrapper.find('.course-address-csz').text(csz);
    $selectedCourseWrapper.find('.get-directions')[0].href = directionsUrl(course);
    $selectedCourseWrapper.find('.learn-more')[0].href = `/courses/${course.id}`;
    $selectedCourseWrapper.show();
  };

  const scrollToSelectedCourse = function () {
    const scrollOffset = $('.selected')[0].offsetTop - $courseListing[0].offsetTop;
    $courseListing.animate({ scrollTop: scrollOffset }, 'slow');
  };

  const hideSelectedCourse = function () {
    $selectedCourseWrapper.hide();
    markers.forEach(function (marker) {
      marker.setIcon(markerIcons.blue);
    });
  };

  const displayTitle = function (title) {
    // Truncate the long "New Reality..." course type title.
    if (title.toLowerCase().indexOf('the new reality') >= 0) {
      title = 'The New Reality';
    }
    return title;
  };

  const directionsUrl = function (course) {
    // Note: for whatever reason, Google decided that two slashes after 'dir//'
    // should be used to indicate this is a map destination, and not the starting point.
    const lat = course.location.latitude;
    const lng = course.location.longitude;
    return `https://www.google.com/maps/dir//${lat},+${lng}/@${lat},${lng},${ADDR_ZOOM_LEVEL}z/`;
  };

  const showNoResultsForm = function () {
    $noResultsQuery.text($searchInput.val());
    $noResultsOverlay.show();
    $resultsRow.hide();
    updateSearchPlaceholder([]);
  };

  const activateListingOnMap = function () {
    const markerIndex = parseInt($(this).data('mapindex'));
    /* eslint-disable no-new, new-cap */
    new google.maps.event.trigger(markers[markerIndex], 'click');
    /* eslint-enable no-new, new-cap */

    // When triggering a course from the sidebar: zoom and center the marker
    map.setOptions({ zoom: ADDR_ZOOM_LEVEL, center: markers[markerIndex].getPosition() });
  };

  const updateScrollIndicator = function () {
    const scrolledToBottom = $courseListing.scrollTop() + $courseListing.outerHeight() + 10 >= $courseListing.get(0).scrollHeight;
    if (scrolledToBottom) {
      $('.reveal-down').addClass('hidden');
    } else {
      $('.reveal-down').removeClass('hidden');
    }
  };

  return {
    init: init
  };
})();

$(document).on('turbolinks:load', courseSearch.init);

import $ from 'jquery';
import showdown from 'showdown'; // Showdown Markdown Parser
import xssFilter from 'showdown-xss-filter'; // Showdown extension

const markdownEditor = (function () {
  const PREVIEW_TAB = 'preview';
  // We keep the converter outside of the class so that we don't create multiple instances
  const mdConverter = new showdown.Converter({ extensions: [xssFilter] });

  // This component utilizes the app/views/shared/_md_editor.html.erb partial
  class MarkdownEditor {
    // aka Destructuring Assignment
    // Example: https://simonsmith.io/destructuring-objects-as-function-parameters-in-es6
    constructor ({ rootElement }) {
      // The rest of the elements are searched from this part of the DOM tree
      this.$root = $(rootElement);

      this.$markdownTextArea = this.$root.find('textarea');
      this.$previewPane = this.$root.find('.markdown-preview-pane');
      this.$previewTab = this.$root.find('.markdown-preview-tab');
      this.$tabs = this.$root.find('a[data-toggle="tab"]');
    }

    initPreview () {
      const textAreaNotFound = this.$markdownTextArea.length < 1;
      if (textAreaNotFound) {
        throw new Error('Need a textarea input field to use markdown_editor.js');
      }

      // If textarea field has content, switch to preview tab and render it
      const content = this.$markdownTextArea.val();
      const contentIsEmpty = content.trim().length < 1;
      if (!contentIsEmpty) {
        this.convertPreviewPane();
        this.$previewTab.tab('show');
      }

      // https://getbootstrap.com/docs/4.0/components/navs/#events
      this.$tabs.on('show.bs.tab', (e) => {
        const $newTab = $(e.target);
        const previewRequested = $newTab.data('tab') === PREVIEW_TAB;
        if (previewRequested) {
          this.convertPreviewPane();
        }
      });
    }

    convertPreviewPane () {
      const markdown = this.$markdownTextArea.val();
      const html = mdConverter.makeHtml(markdown);
      this.$previewPane.html(html);
    }

    // Static because we don't require an instance of this class to use this feature
    static convertMdToHtml (element) {
      const markdown = $(element).text();
      const html = mdConverter.makeHtml(markdown);

      $(element).html(html);
    }
  }

  const createEditorPreview = function (_index, element) {
    // We create distinct MarkdownEditors because each will be responsible for a different part of
    // the DOM, and will have its own internal state associated with that part of the DOM.
    const mdEditor = new MarkdownEditor({ rootElement: element });
    mdEditor.initPreview();
  };

  const createHtmlFromMd = function (_index, element) {
    MarkdownEditor.convertMdToHtml(element);
  };

  const init = function () {
    $('[data-behavior~=markdown-editor]').each(createEditorPreview);
    $('[data-behavior~=md-to-html]').each(createHtmlFromMd);
  };

  return {
    init: init
  };
})();

$(document).on('turbolinks:load', markdownEditor.init);

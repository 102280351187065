// Set up the reminder modal functionality for the course and location
// registration pages
const registrationReminder = (function () {
  let firstTime = true;
  let $reminderModalContainer;
  let $reminderModal;
  let $exitModalButtons;
  let $learnMoreButton;

  const showModal = function () {
    const modalIsActive = $('#modal-window').hasClass('show');
    const alreadyRegistered = $('#modal-window').data('registered');

    // Only show if we haven't already, and the user hasn't already registered,
    // and the registration form isn't already showing.
    if (firstTime && !alreadyRegistered && !modalIsActive) {
      $reminderModalContainer.addClass('active');
      $('html').addClass('overflow-hidden');
      firstTime = false;
    }
  };

  const closeModal = function (event) {
    event.preventDefault();
    const keyPressIsNotEscape = (event.type === 'keyup' && event.key !== 'Escape');
    const modalIsClosed = !$reminderModalContainer.hasClass('active');
    if (keyPressIsNotEscape || modalIsClosed) {
      return;
    }
    $('html').removeClass('overflow-hidden');
    $reminderModalContainer.removeClass('active');
  };

  const openRegistrationModal = function (event) {
    closeModal(event); // Close the modal we are in first
    $('.rwa-button.course-register').trigger('click');
  };

  const init = function () {
    const onCoursePage = $('body[data-controller="public"]').attr('data-action') === 'course';
    const onLocationPage = $('body[data-controller="locations"]').attr('data-action') === 'public';
    // If we aren't on a course or location page, bail out
    if (!onCoursePage && !onLocationPage) {
      return;
    }
    $reminderModalContainer = $('.reminder-modal-container');
    $reminderModal = $('.reminder-modal');
    $exitModalButtons = $('.reminder-modal .reg-modal-exit, .reminder-modal .course-cancel');
    $learnMoreButton = $('.reminder-modal .course-register');

    $(window).on('blur', showModal);
    $(window).on('keyup', closeModal);
    $reminderModalContainer.on('click', closeModal);
    $reminderModal.on('click', (e) => e.stopPropagation());
    $learnMoreButton.on('click', openRegistrationModal);
    $exitModalButtons.on('click', closeModal);
  };

  return {
    init: init
  };
})();

$(document).on('turbolinks:load', registrationReminder.init);
